var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
        [
          _vm.pos == "Prestation de Services"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Prestation de Services")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _c("span", [
                                _vm._v(
                                  "Nombre de Prestation demandé: " +
                                    _vm._s(_vm.fields.length) +
                                    " / Montant Vérification: " +
                                    _vm._s(
                                      new Intl.NumberFormat("de-DE", {
                                        style: "currency",
                                        currency: "DZD"
                                      }).format(_vm.getAmount())
                                    ) +
                                    " "
                                )
                              ]),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "b-card",
                                  { attrs: { title: "Personnel Reçu" } },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "table mt-2" },
                                      [
                                        _c("thead", [
                                          _c("th", [_vm._v("Poste")]),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Nombres demandé")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Nombres réalisé")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Unité")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-right" },
                                            [_vm._v("Confirmer Nombres")]
                                          )
                                        ]),
                                        _vm._l(_vm.fields, function(field) {
                                          return _c(
                                            "tr",
                                            { key: field.poste.id },
                                            [
                                              _c("th", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(field.poste.name) +
                                                    " "
                                                )
                                              ]),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(field.qte) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(field.prestQte) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        field.uom.designation
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: { width: "33%" }
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: field.checkQte,
                                                        expression:
                                                          "field.checkQte"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "number",
                                                      min: "0"
                                                    },
                                                    domProps: {
                                                      value: field.checkQte
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.isNumber(
                                                          $event
                                                        )
                                                      },
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            field,
                                                            "checkQte",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.getAmount()
                                                        }
                                                      ]
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Acquisition Fournitures"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Produits")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "table",
                          { staticClass: "table mt-2" },
                          [
                            _c("thead", [
                              _c("th", [_vm._v("Produit")]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité demandé")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité envoyé")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Confirmer Quantité")
                              ])
                            ]),
                            _vm._l(_vm.selectedProducts, function(product) {
                              return _c("tr", { key: product.product.id }, [
                                _c("th", [
                                  _vm._v(
                                    " " +
                                      _vm._s(product.product.designation) +
                                      " "
                                  )
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(product.qte) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(product.prestQte) + " ")
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "33%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: product.checkQte,
                                          expression: "product.checkQte"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "0" },
                                      domProps: { value: product.checkQte },
                                      on: {
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            product,
                                            "checkQte",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }