var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-md-6 d-flex justify-content-between" },
              [
                _c("VueCtkDateTimePicker", {
                  staticStyle: { display: "inline-block" },
                  attrs: {
                    locale: "fr",
                    range: true,
                    color: "#34495e",
                    "no-label": true,
                    "custom-shortcuts": _vm.customDateRangeShortcuts,
                    "only-date": true,
                    "auto-close": true,
                    "no-header": true
                  },
                  model: {
                    value: _vm.dateRange,
                    callback: function($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info ml-2",
                    on: {
                      click: function($event) {
                        return _vm.searchODS()
                      }
                    }
                  },
                  [_vm._v("Recherche")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-6 d-flex justify-content-end" }, [
              _c("div", { staticClass: "btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.ArchivedListODS("Archived")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-archive" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "badge bg-success text-white" }, [
                      _vm._v(_vm._s(_vm.ArchivedODS.length))
                    ]),
                    _vm._v(" Archives ")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.ArchivedListODS("InProgress")
                      }
                    }
                  },
                  [
                    _c("b-spinner", {
                      staticClass: "mb-1",
                      attrs: { variant: "warning", small: "" }
                    }),
                    _c(
                      "span",
                      { staticClass: "badge bg-warning text-white ml-1" },
                      [_vm._v(" " + _vm._s(_vm.InProgressODS.length))]
                    ),
                    _vm._v(" En cours ")
                  ],
                  1
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" },
                          on: {
                            click: function($event) {
                              _vm.active = !_vm.active
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Liste Ordres de Service (Opérations 3D)")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-0",
                        visible: _vm.active,
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "div",
                          { staticClass: "table-responsive mb-0 shadow" },
                          [
                            _c("dataset", {
                              attrs: { "ds-data": _vm.List_ODS },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var ds = ref.ds
                                    return [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c("div", {}, [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-hover d-md-table"
                                                },
                                                [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      _vm._l(_vm.cols, function(
                                                        th
                                                      ) {
                                                        return _c(
                                                          "th",
                                                          {
                                                            key: th.field,
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  th.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    )
                                                  ]),
                                                  _c("dataset-item", {
                                                    attrs: { tag: "tbody" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            var rowIndex =
                                                              ref.rowIndex
                                                            return [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  class: {
                                                                    "bg-soft-info":
                                                                      _vm.selectedIndex ==
                                                                      rowIndex
                                                                  },
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.selectRow(
                                                                        row,
                                                                        rowIndex
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            row.ref
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row
                                                                            .lifebase
                                                                            .name
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row
                                                                            .contract
                                                                            .ref
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row
                                                                            .service_provider
                                                                            .prestname
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.date_ODS_format
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row.date_exec_ODS_format
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          row
                                                                            .totalAmnt
                                                                            .withCurrency
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("td", {
                                                                    staticClass:
                                                                      "text-center",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        row.status_label
                                                                      )
                                                                    }
                                                                  }),
                                                                  row.done == 1
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-check-circle text-success"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-times-circle text-danger"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                  row.verified ==
                                                                  1
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-check-circle text-success"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-times-circle text-danger"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                  row.verified ==
                                                                  1
                                                                    ? _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              row
                                                                                .totalAmntVerified
                                                                                .withCurrency
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                        },
                                        [
                                          _c("dataset-show", {
                                            attrs: { "ds-show-entries": 5 }
                                          }),
                                          _c("dataset-pager")
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c("RecepForm", {
                attrs: {
                  ODS: _vm.selectedRows,
                  leftAmountContract: _vm.leftAmount
                },
                on: {
                  odsvalues: function($event) {
                    _vm.ODS = $event
                  },
                  getContractID: function($event) {
                    return _vm.leftAmountInContract($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }